import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@module/shared/_services/auth-guard/auth-guard.service';
import { NotFoundComponent } from '@module/shared/_components/not-found/not-found.component';
const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '/location',
  //   pathMatch: 'full',
  // },
  {
    path: 'location',
    // canActivate: [AuthGuardService],
    loadChildren: () =>
      import('@module/location/location.module').then(
        (mod) => mod.LocationModule
      ),
  },
  {
    path: 'map',
    // canActivate: [AuthGuardService],
    loadChildren: () =>
      import('@module/map/map.module').then((mod) => mod.MapModule),
  },
  {
    path: 'emergency',
    // canActivate: [AuthGuardService],
    loadChildren: () =>
      import('@module/emergency/emergency.module').then((mod) => mod.EmergencyModule),
  },
  {
    path: 'sessionexpire',
    // canActivate: [AuthGuardService],
    loadChildren: () =>
      import('@module/session/session.module').then((mod) => mod.SessionModule),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}
